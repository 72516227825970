<template>
  <modal
    :show.sync="isShowModal"
    modal-classes="modal-secondary"
    @close="close()"
    @show="show()"
  >
    <h6 slot="header" class="modal-title text-uppercase">Edit Member</h6>
    <validation-observer ref="formValidator">
      <div class="row">
        <div class="col-md-6">
          <base-input
            v-model="formGroup.member_id.value"
            :rules="{ max: 3, min: 3 }"
            label="MemberShip Id"
            name="Member display id"
            placeholder="Enter MemberShip Id"
          ></base-input>
        </div>

        <div class="col-md-6">
          <base-selectbox
            v-model="formGroup.is_active.value"
            :rules="formGroup.is_active.rule"
            :options="source.status"
            placeholder="Select Status"
            label="Status"
            name="Status"
            size="small"
          ></base-selectbox>
        </div>

        <div class="col-md-6">
          <base-selectbox
            v-model="formGroup.vat_status.value"
            :rules="formGroup.vat_status.rule"
            :options="source.vat_status"
            placeholder="Select Vat Verify"
            label="Vat Number Verify"
            name="vat_verify"
            size="small"
          ></base-selectbox>
        </div>
      </div>
    </validation-observer>
    <template v-slot:footer>
      <base-button type="primary" :loading="loading" @click="editmember">
        Submit
      </base-button>
      <base-button
        class="ml-auto"
        type="primary"
        :outline="true"
        @click="close()"
      >
        Close
      </base-button>
    </template>
  </modal>
</template>

<script>
import { FormGroup } from "@/core/models";
import { membersService, notificationService } from "@/core/services";

export default {
  props: {
    showAddUserModalForm: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
    edite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalState: false,
      formGroup: new FormGroup({
        member_id: [null],
        is_active: [null],
        vat_status: [null],
      }),
      titleValue: {
        type: String,
        default: "",
      },
      source: {
        status: [
          { label: "Active", value: "Active" },
          { label: "Blocked", value: "Blocked" },
        ],
        vat_status: [
          { label: "Verified", value: "Verified" },
          { label: "Rejected", value: "Rejected" },
        ],
      },
      loading: false,
    };
  },
  computed: {
    isShowModal: {
      get() {
        return this.showAddUserModalForm;
      },
      set(value) {
        this.modalState = value;
      },
    },
  },
  methods: {
    async editmember() {
      const validate = await this.$refs.formValidator.validate();

      if (validate) {
        const payload = {
          ...this.formGroup.value,
          is_active: this.formGroup.is_active.value == "Active" ? true : false,
          vat_status:
            this.formGroup.vat_status.value == "Verified" ? true : false,
        };

        this.loading = true;

        membersService
          .attribute(this.item.id, payload)
          .then((res) => {
            notificationService.success(res.message);
            this.close();
          })
          .finally(() => (this.loading = false));
      }
    },
    show() {
      if (this.item.id) {
        this.titleValue = "Edit Member";
        const formGroup = {
          member_id: this.item.member_id,
          is_active: this.item.is_active ? "Active" : "Blocked",
          vat_status:
            this.item.user_billing.vat_status != null
              ? this.item.user_billing.vat_status
                ? "Verified"
                : "Rejected"
              : null,
        };

        this.formGroup.populate(formGroup);
      }
    },
    close() {
      this.isShowModal = false;
      this.$emit("close");
      this.$refs.formValidator.reset();
      this.formGroup.reset();
      //console.log(this.formGroup);
    },
  },
};
</script>

<style lang="scss" scoped>
.heading-separator {
  border-bottom: 1px solid #968888;
  margin-bottom: 1.5em;
  margin-top: 1em;
}
</style>
