<template>
  <div>
    <div
      class="dt-detail-info__content-sec dt-detail-info__content-sec--customer"
    >
      <br />
      <div class="row address">
        <div class="col-md-6">
          <span class="val">{{ formatData(item.joined_at) }}</span>
        </div>
      </div>
      <div class="row address">
        <div class="col-md-6">
          <span class="val">Shadow accounts: {{ item.shadow_user_count }}</span>
        </div>
      </div>
      <div class="row address">
        <div class="col-md-6">
          <div v-for="shadow_user in item.shadow_user" :key="shadow_user.id">
            <span class="val">{{ shadow_user.full_name }}</span>
          </div>
        </div>
      </div>
      <br />
      <div class="row address">
        <div class="col-md-6">
          <span class="val">
            Total flight hours:
            {{
              Math.floor(item.total_hours_travelled / 60) +
              ":" +
              (item.total_hours_travelled % 60)
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    formatData(date) {
      return moment(date).format("Y-MM-DD HH:MM:ss");
    },
  },
};
</script>

<style lang="scss" scoped></style>
