<template>
  <div>
    <div
      class="dt-detail-info__content-sec dt-detail-info__content-sec--customer"
    >
      <div class="row address">
        <div class="col-md-6">
          <span class="val">Invitations: {{ item.invitations.length }}</span>
          <div v-for="invitation in item.invitations" :key="invitation.id">
            <span class="val">
              {{ invitation.name + " - " + invitation.status }}
            </span>
          </div>
        </div>
        <br />
      </div>
      <base-button
        v-permission="[GRANT.ADD_MEMBER_INVITE]"
        size="sm"
        type="primary"
        @click="toggleAddInvitaitionForm(true)"
      >
        <span class="fas fa-plus"></span>
        Add Invitation
      </base-button>

      <br />

      <div class="row address">
        <div class="col-md-6">
          <!-- <span class="val">
            Terms and conditions: ver 1.0 2021-07-01 16.32
          </span> -->
        </div>
      </div>
    </div>
    <add-invitation
      :show-add-invitaition-modal-form="showAddInvitaitionModalForm"
      :item="item"
      @close="toggleAddInvitaitionForm(false)"
    ></add-invitation>
  </div>
</template>

<script>
import AddInvitation from "./AddInvitation.vue";
import { GRANT } from "@/core/enums";

export default {
  components: {
    AddInvitation,
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      showAddInvitaitionModalForm: false,
      GRANT: GRANT,
    };
  },
  computed: {},
  methods: {
    toggleAddInvitaitionForm(val) {
      this.showAddInvitaitionModalForm = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
