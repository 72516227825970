<template>
  <div>
    <div class="flight-content">
      <div class="section dt-detail-info">
        <div class="row dt-detail-info__header">
          <div class="col-md-12 heading">
            Member Details {{ item.member_display_id }}
          </div>
        </div>

        <customer :item="item"></customer>
        <joined-detail :item="item"></joined-detail>
        <last-flight :item="item"></last-flight>
        <token :item="item"></token>
        <invitaition :item="item"></invitaition>
      </div>
      <actions :item="item"></actions>
    </div>
  </div>
</template>

<script>
import { flightService } from "@/core/services";
import Customer from "./Customer.vue";
import JoinedDetail from "./JoinedDetail.vue";
import LastFlight from "./LastFlight.vue";
import Actions from "./Actions.vue";
import Invitaition from "./Invitation.vue";
import Token from "./Token.vue";

export default {
  name: "MemberDetails",
  components: {
    Customer,
    Actions,
    JoinedDetail,
    LastFlight,
    Token,
    Invitaition,
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    addNote(payload) {
      return flightService.saveNote(this.item.id, payload).then((res) => {
        this.item.comments = [...res.data.comments];
        return res;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
