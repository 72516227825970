<template>
  <modal
    :show.sync="isShowModal"
    modal-classes="modal-secondary"
    :backdrop-close="true"
    size="lg"
    @close="close()"
  >
    <h6 slot="header" class="modal-title text-uppercase">Add Invitation</h6>
    <validation-observer ref="formValidator">
      <div class="row">
        <div class="col-md-6">
          <base-input
            v-model="formGroup.name.value"
            :rules="formGroup.name.rule"
            label="Name"
            name="name"
            placeholder="Enter Name"
          ></base-input>
        </div>

        <div class="col-md-6">
          <base-input
            v-model="formGroup.phone.value"
            :rules="formGroup.phone.rule"
            label="Phone"
            name="phone"
            placeholder="Enter PhoneNo"
          ></base-input>
        </div>
      </div>
    </validation-observer>
    <template v-slot:footer>
      <base-button type="primary" :loading="loading" @click="saveInvitation">
        Submit
      </base-button>
      <base-button
        class="ml-auto"
        type="primary"
        :outline="true"
        @click="close()"
      >
        Close
      </base-button>
    </template>
  </modal>
</template>

<script>
import { FormGroup } from "@/core/models";
import { invitationService, notificationService } from "@/core/services";

export default {
  props: {
    showAddInvitaitionModalForm: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      modalState: false,
      formGroup: new FormGroup({
        name: [null, { required: true }],
        phone: [null, { required: true }],
      }),
      source: {
        status: [
          { label: "Active", value: "1" },
          { label: "In-Active", value: "0" },
        ],
      },
      loading: false,
    };
  },
  computed: {
    isShowModal: {
      get() {
        return this.showAddInvitaitionModalForm;
      },
      set(value) {
        this.modalState = value;
      },
    },
  },
  methods: {
    async saveInvitation() {
      const validate = await this.$refs.formValidator.validate();
      if (validate) {
        console.log(this.formGroup.value);
        this.loading = true;

        invitationService
          .saveInvitation(this.formGroup.value)
          .then((res) => {
            notificationService.success(res.message);
            this.$store.commit("member/updateInvitation", {
              id: this.item.id,
              response: res.data,
            });
            this.close();
          })
          .finally(() => (this.loading = false));
      }
    },
    close() {
      this.isShowModal = false;
      this.$emit("close");
      this.$refs.formValidator.reset();
      this.formGroup.reset();
      //console.log(this.formGroup);
    },
  },
};
</script>

<style lang="scss" scoped>
.heading-separator {
  border-bottom: 1px solid #968888;
  margin-bottom: 1.5em;
  margin-top: 1em;
}
</style>
