<template>
  <div>
    <div class="dt-detail-footer">
      <div class="action-list">
        <div class="action-list__item">
          <a href="javascript:void(0)" @click="delete_member()">
            Delete Member
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  membersService,
  confirmDialogService,
  notificationService,
} from "@/core/services";

export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    delete_member() {
      confirmDialogService.open(
        "Are you sure you want to delete this member?",
        () => {
          membersService.drop(this.item.id).then((_res) => {
            notificationService.success(_res.message);
          });
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
