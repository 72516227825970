<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0 breadcrum-heading">
            Member Detail
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-card-body>
          <b-row>
            <b-col md="12">
              <data-table
                :table-columns="tableColumns"
                :table-data="membersList"
                :border="true"
                row-class-name="row-expanded"
                :loading="loading"
              >
                <template #expandable="{ item }">
                  <member-details :item="item"></member-details>
                </template>

                <!-- <template #header-action>
                  <base-button
                    size="md"
                    type="primary"
                    @click="toggleAddUserForm(true)"
                  >
                    <span class="fas fa-plus"></span>
                    Add Member
                  </base-button>
                </template> -->
              </data-table>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-container>
    <add-member
      :show-add-user-modal-form="showAddUserModalForm"
      @close="toggleAddUserForm(false)"
    ></add-member>
  </div>
</template>

<script>
import { RouteBreadCrumb } from "@/components";
import { membersService } from "@/core/services";
import AddMember from "./component/AddMember.vue";
import MemberDetails from "./component/Details.vue";

import { mapGetters } from "vuex";

export default {
  name: "Members",
  components: {
    RouteBreadCrumb,
    AddMember,
    MemberDetails,
  },
  data() {
    return {
      tableColumns: [
        {
          prop: "full_name",
          label: "Name",
          sortable: true,
          searchable: true,
        },
        {
          prop: "phone",
          label: "Nr",
          formatter: (row, _column, _cellValue) => {
            return row.phone ? row.member_id : null;
          },
          searchable: true,
        },

        {
          prop: "company",
          label: "Company",
          formatter: (row, _column, _cellValue) => {
            return row.user_billing.company;
          },
          searchable: true,
        },
        {
          prop: "balance",
          label: "Balance",
          formatter: (row, _column, _cellValue) => {
            return row.rewards_balance;
          },
          searchable: true,
        },

        {
          prop: "is_active",
          label: "Status",
          formatter: (row, _column, _cellValue) => {
            return row.is_active ? "active" : "blocked";
          },
          searchable: true,
          component: "badge",
          variant: {
            active: "fl-success",
            cancelled: "fl-danger",
          },
        },
      ],
      tableData: [],
      loading: false,
      showAddUserModalForm: false,
    };
  },
  computed: {
    dtRef() {
      return this.$refs.dt;
    },
    ...mapGetters("member", ["membersList"]),
  },
  mounted() {
    this.getAllUsers();
  },
  methods: {
    toggleAddUserForm(val) {
      this.showAddUserModalForm = val;
    },
    getAllUsers() {
      this.loading = true;
      membersService.members().finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .dt-detail-info {
    &__content-sec {
      &--passenger {
        .heading {
          margin-bottom: 0.8em;
          margin-top: 1em;
          font-size: 0.8rem;
          font-weight: 700;
        }
        .pass-detail {
          margin-bottom: 0.5em;
        }
      }
      &--pricing {
        .price-detail {
          margin-bottom: 0.8em;
          .col-md-3 {
            display: flex;
          }
          .badge {
            margin-right: 1em;
          }
          .time-label {
            font-weight: 500;
            width: 40%;
          }
          .time-value {
            font-weight: 500;
          }
        }
      }
      &--customer {
        .address {
          padding-bottom: 0.18em;
          .label {
            font-weight: 600;
            color: #807d7d;
            padding-right: 1em;
          }
        }
      }
    }
  }
}
</style>
