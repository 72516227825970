<template>
  <div>
    <div
      class="dt-detail-info__content-sec dt-detail-info__content-sec--customer"
    >
      <br />
      <div class="row address">
        <div class="col-md-6">
          <span class="label">Token:</span>
          <div v-for="reward in item.rewards" :key="reward.id">
            <br />

            <span class="val">
              {{ reward.date + "  " + reward.type + "  " + reward.token_count }}
            </span>
          </div>
          <br />
          <br />
          <base-button
            v-permission="[GRANT.ADD_MEMBER_TOKEN]"
            size="sm"
            type="primary"
            @click="toggleAddInvitaitionForm(true)"
          >
            <span class="fas fa-plus"></span>
            Add Reward Token
          </base-button>

          <br />
          <br />
          <base-button
            v-permission="[GRANT.ADD_MEMBER_TOKEN]"
            size="sm"
            type="danger"
            @click="toggleRemoveRewardForm(true)"
          >
            <span class="fas fa-minus"></span>
            Remove Reward Token
          </base-button>

          <br />
          <br />
          <span class="val">Invited by: {{ item.invited_by.full_name }}</span>
        </div>
        <br />
      </div>
    </div>
    <add-reward
      :show-add-invitaition-modal-form="showAddInvitaitionModalForm"
      :item="item"
      @close="toggleAddInvitaitionForm(false)"
    ></add-reward>
    <remove-reward
      :show-remove-reward-modal-form="showRemoveRewardModalForm"
      :item="item"
      @close="toggleRemoveRewardForm(false)"
    ></remove-reward>
  </div>
</template>

<script>
import AddReward from "./AddReward.vue";
import RemoveReward from "./RemoveReward.vue";
import { GRANT } from "@/core/enums";
export default {
  components: {
    AddReward,
    RemoveReward,
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      showAddInvitaitionModalForm: false,
      showRemoveRewardModalForm: false,
      GRANT: GRANT,
    };
  },
  computed: {},
  methods: {
    toggleAddInvitaitionForm(val) {
      this.showAddInvitaitionModalForm = val;
    },
    toggleRemoveRewardForm(val) {
      this.showRemoveRewardModalForm = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
