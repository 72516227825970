<template>
  <div>
    <div
      class="dt-detail-info__content-sec dt-detail-info__content-sec--customer"
    >
      <div class="row address">
        <div class="col-md-3">
          <span class="val">{{ item.full_name }}</span>
        </div>
        <div class="col-md-3 val">nr: {{ item.member_display_id }}</div>

        <div v-if="item.user_billing.vat_number" class="col-md-3 val">
          <span v-if="item.user_billing.vat_status" class="label-sm">
            Vat Status: Verified
          </span>
          <span
            v-else-if="item.user_billing.vat_status == false"
            class="label-sm"
          >
            Vat Status : Rejected
          </span>
          <span v-else class="label-sm">Vat Status : Pending</span>
        </div>
        <div class="col-md-3 val">
          <i
            class="fas fa-pencil-alt text-primary cursor-pointer"
            @click="toggleAddUserForm(true, item, true)"
          ></i>
        </div>
      </div>
      <div class="row address">
        <div class="col-md-6">
          <span class="val">{{ item.user_billing.company }}</span>
        </div>
      </div>

      <div class="row address">
        <div class="col-md-6">
          <span class="val">{{ item.user_billing.vat_number }}</span>
        </div>
      </div>
      <div class="row address">
        <div class="col-md-6">
          <span class="val">{{ item.phone }}</span>
        </div>
      </div>
      <div class="row address">
        <div class="col-md-6">
          <span class="val">{{ item.email }}</span>
        </div>
      </div>
      <div class="row address">
        <div class="col-md-6">
          <span class="val">{{ item.user_billing.street_address }}</span>
        </div>
      </div>
    </div>
    <add-member
      :show-add-user-modal-form="showAddUserModalForm"
      :item="itemData"
      :edite="editeOption"
      @close="toggleAddUserForm(false)"
    ></add-member>
  </div>
</template>

<script>
import { confirmDialogService } from "@/core/services";
import { membersService, notificationService } from "@/core/services";
import AddMember from "./AddMember.vue";
export default {
  components: { AddMember },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      vatStatus: false,
      editeOption: false,
      showAddUserModalForm: false,
      itemData: {
        type: Object,
      },
    };
  },
  computed: {},
  watch: {
    item: {
      immediate: true,
      handler(newval, _oldval) {
        console.log("newval", newval);
        if (newval) {
          this.vatStatus = newval.user_billing.vat_status;
        }
      },
    },
  },
  methods: {
    onCheckedAddon(message, vat) {
      confirmDialogService.open(
        `Are you sure you want to add this  ${message} addon  in this flight?`,
        () => {
          membersService.attribute(vat.id, this.vatStatus).then((res) => {
            this.item.user_billing.vat_status = this.vatStatus;
            notificationService.success(res.message);
          });
        },
        {
          cancelCallback: () => {
            this.vatStatus = this.item.user_billing.vat_status;
          },
        }
      );
    },
    toggleAddUserForm(val, item, editoption) {
      this.showAddUserModalForm = val;
      this.itemData = item;
      this.editeOption = editoption;
    },
  },
};
</script>

<style lang="scss" scoped></style>
