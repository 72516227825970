<template>
  <div>
    <div
      class="dt-detail-info__content-sec dt-detail-info__content-sec--customer"
    >
      <div class="row address">
        <div class="col-md-6">
          <span class="label">Last flights:</span>
          <div v-for="flightdetail in item.last_flights" :key="flightdetail.id">
            <br />
            <span class="val">
              {{
                flightdetail.departure_date +
                " " +
                flightdetail.departure_time +
                " " +
                flightdetail.from +
                "(" +
                flightdetail.from_name +
                ")" +
                " - " +
                flightdetail.to +
                "(" +
                flightdetail.to_name +
                ")" +
                " " +
                flightdetail.arrival_time
              }}
            </span>
            <br />
          </div>
        </div>
      </div>
      <br />
      <div class="row address">
        <div class="col-md-6">
          <span class="val">Balance: {{ item.rewards_balance }} €</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
